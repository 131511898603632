import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';
import { combineAndMergeFees } from '../custom';
import mountAndBalance from '../custom/mount-and-balance/mount-and-balance';
import phoneNumberValidation from '../custom/phone-number-validation/phone-number-validation';

export const billingStepLoadingHandler = (event: EbizioEvent) => {
  logger('Billing Step Loading');
  console.log(event.detail);

  combineAndMergeFees(event.detail);
  mountAndBalance(event.detail);
  phoneNumberValidation();
};

export const billingStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.BILLING_STEP_LOADING,
  handler: billingStepLoadingHandler,
};

export type LoggerSeverity = 'default' | 'error' | 'warning';

export const logger = (
  message = '',
  severity: LoggerSeverity = 'default',
  ...otherProps: any
): void => {
  switch (severity) {
    case 'error':
      return console.error(`[Ebizio Events] ${message}`, ...otherProps);
    case 'warning':
      return console.warn(`[Ebizio Events] ${message}`, ...otherProps);
    default:
      return console.log(`[Ebizio Events] ${message}`, ...otherProps);
  }
};

import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';
import { combineAndMergeFees } from '../custom';
import extendProductImage from '../custom/extend-product-image/extend-product-image';

export const cartSummaryModalOpenedHandler = (event: EbizioEvent) => {
  logger('Cart Summary Modal Opened');
  console.log(event.detail);

  combineAndMergeFees(event.detail);
  extendProductImage(event.detail);
};

export const cartSummaryModalOpenedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CART_SUMMARY_MODAL_OPENED,
  handler: cartSummaryModalOpenedHandler,
};

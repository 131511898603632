import { EbizioEventDetails } from '../../types';
import { lang } from '../../utils';
import { ResponseData, updateCustomerMessage } from '../../utils/api';

const message = lang('add_comment_for_terms_consent.message');
let placeOrderNeedsDisabling = true;

export const addCommentForTermsConsent = (detail: EbizioEventDetails) => {
  const checkoutId = detail.cart?.id;
  const consent = document.getElementById('terms');

  const placeOrder = document.getElementById('checkout-payment-continue');
  if (placeOrder) {
    new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        const target = mutation.target as HTMLElement;
        if (!target?.getAttribute('disabled') && placeOrderNeedsDisabling) {
          disablePlaceOrder(target);
          // console.log('disabled order button', target, target.getAttribute('disabled'));
        }
      });
    }).observe(placeOrder, {
      attributes: true,
      attributeFilter: ['disabled'],
    });
  }

  consent?.addEventListener('change', (event) => {
    const inputElement = event.currentTarget as HTMLInputElement;
    if (checkoutId) {
      updateComment(checkoutId, inputElement, detail, placeOrder);
      placeOrderNeedsDisabling = !inputElement.checked;
      if (placeOrderNeedsDisabling) disablePlaceOrder(placeOrder);
    }
  });

  if (checkoutId)
    updateComment(checkoutId, consent as HTMLInputElement, detail, placeOrder);
};

const updateComment = (
  checkoutId: string,
  consentCheckbox: HTMLInputElement,
  event: {
    customerMessage: string;
    refreshCartData: Function;
  },
  placeOrder: HTMLElement | null,
) => {
  const customerConsents = consentCheckbox.checked;
  const countAttempts = (
    promiseGenerator: () => Promise<ResponseData>,
    attempt: number = 1,
  ) => {
    promiseGenerator()
      .then((response) => {
        if (response.isError) {
          if (attempt <= 3) {
            console.log(
              `Customer comment update failed, ${
                attempt < 3
                  ? `trying again with attempt ${attempt + 1}`
                  : 'giving up'
              }`,
            );
            setTimeout(() => {
              countAttempts(promiseGenerator, attempt + 1);
            }, 500);
          } else {
            if (placeOrder) enablePlaceOrder(placeOrder); // let customer through anyway
            throw response.message;
          }
        } else if (response.data) {
          console.log(
            `Updated customer comment to: "${response?.data?.customerMessage}" on attempt ${attempt}`,
          );
          return event.refreshCartData();
        }
      })
      .then(() => {
        if (placeOrder) enablePlaceOrder(placeOrder);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  countAttempts(() =>
    updateCustomerMessage(
      checkoutId,
      customerConsents
        ? addConsentString(event.customerMessage)
        : removeConsentString(event.customerMessage),
    ),
  );
};

const removeConsentString = (comment: string) =>
  comment.includes(message) ? comment.replace(`${message}`, '') : comment;

const addConsentString = (comment: string) =>
  comment.includes(message) ? comment : `${comment}\n${message}`;

const disablePlaceOrder = (el: HTMLElement | null) => {
  if (el) {
    el.setAttribute('disabled', 'true');
    console.log('disabled order button', el);
  }
};

const enablePlaceOrder = (el: HTMLElement | null) => {
  if (el) {
    el.removeAttribute('disabled');
    console.log('enabled order button', el);
  }
};

import { CHECKOUT_ID } from '../../constants';
import { EbizioEventDetails } from '../../types';
import swal from 'sweetalert2';

declare global {
  interface Window {
    storeHash: string;
    awaitingPaytomorrow: Boolean;
  }
}

const fetchWithRetry = (input: string, options = {}, attempts = 3, delay = 0) =>
  new Promise((res, rej) => {
    let retriesRemaining = attempts;
    const makeFetch = () => {
      retriesRemaining -= 1;
      console.log('trying with ', retriesRemaining, ' retries remaining');
      return fetch(input, options);
    };
    const responseCallback: any = async (response: Response) => {
      try {
        const responseData = await response.json();
        if (responseData?.data?.entry_id) {
          res(responseData);
        } else {
          return retriesRemaining
            ? setTimeout(() => makeFetch().then(responseCallback), delay)
            : rej(responseData);
        }
      } catch (error) {
        throw error;
      }
    };
    makeFetch()
      .then(responseCallback)
      .catch((error) => rej(error));
  });

const getOrderId = async (checkoutId: string) => {
  const data = await fetch(`/api/storefront/checkout/${checkoutId}`).then(
    (response) => response.json(),
  );
  return data?.orderId;
};

const fireSwal = (orderId: String | Number) => {
  fetchWithRetry(
    `https://bbwheels.intuitsolutions-apps.net${
      ['hljp23cwk2'].includes(window.storeHash) ? ':20443' : ''
    }/app/waterfall/retrieve/${orderId}?store_hash=${window.storeHash}`,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
    10,
    500,
  )
    .then((data) => {
      if ((<any>data)?.data?.entry_id) {
        swal({
          title: 'Is Affirm not right for you?',
          html: `<h2 class="paytomorrow-heading">Apply with <img src="/content/images/checkout/Paytomorrow-logo.png" alt="Paytomorrow" class="paytomorrow-logo" /></h2><h3 class="paytomorrow-subheading">Financing for all credit types</h3>`,
          confirmButtonText: 'Yes, get started',
          showCancelButton: true,
          cancelButtonText: 'No thanks',
          buttonsStyling: false,
          reverseButtons: true,
          confirmButtonClass:
            'button button--primary button--small button--paytomorrow',
          cancelButtonClass:
            'button button--secondary button--small button--paytomorrow',
        })
          .then((accepted) => {
            const payTomorrowOption =
              document.getElementById('radio-bankdeposit');
            if (payTomorrowOption && accepted) {
              window.awaitingPaytomorrow = true;
              payTomorrowOption.click();
            }
          })
          .catch((rejected) => {
            console.log('declined PayTomorrow prompt:', rejected);
          });
      } else {
        throw 'Affirm entry not found. Assuming user cancellation.';
      }
    })
    .catch((error) => console.error(error));
};

export const trackAffirmClose = () => {
  document.head.insertAdjacentHTML(
    'beforeend',
    '<link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/limonte-sweetalert2/6.11.5/sweetalert2.min.css">',
  );

  if (window.MutationObserver) {
    new MutationObserver((mutations) => {
      mutations.forEach(async (mutation) => {
        const affirmIframe = Array.from(mutation?.removedNodes || []).reduce(
          (match, removedNode) =>
            ((<HTMLElement>removedNode).querySelector &&
              (<HTMLElement>removedNode).querySelector(
                '.affirm-sandbox-container #checkout-application',
              )) ||
            match,
          <any>null,
        );
        if (affirmIframe) {
          const orderId = await getOrderId(CHECKOUT_ID);
          fireSwal(orderId);
        }
      });
    }).observe(document.body, { childList: true });

    const affirmContainers = Array.from(
      document.querySelectorAll('affirm-sandbox-container'),
    ).filter((el) => el.querySelector('#affirm_overlay'));

    const watchForStyleChange = (elementToWatch: HTMLElement) => {
      // console.log('Watching for style changes...', elementToWatch);

      return new MutationObserver((mutations) => {
        mutations.forEach(async (mutation) => {
          if (
            (<HTMLElement>mutation.target).style.getPropertyValue('display') ===
            'none'
          ) {
            // console.log('Container is hidden now, running code', mutation.target);

            const orderId = await getOrderId(CHECKOUT_ID);
            fireSwal(orderId);
          }
        });
      }).observe(elementToWatch, {
        attributes: true,
        attributeFilter: ['style'],
      });
    };

    if (affirmContainers.length) {
      affirmContainers.forEach(async (affirmContainer) => {
        if (
          (<HTMLElement>affirmContainer).style.getPropertyValue('display') ===
          'none'
        ) {
          // console.log('Container is hidden now, running code', affirmContainer);

          const orderId = await getOrderId(CHECKOUT_ID);
          fireSwal(orderId);
        } else {
          watchForStyleChange(<HTMLElement>affirmContainer);
        }
      });
    } else {
      // console.log('Watching for added containers...', document.body);

      return new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          const containers = Array.from(mutation.addedNodes).reduce(
            (nodeList: Array<Node>, addedNode) => {
              return (<HTMLElement>addedNode).matches(
                '.affirm-sandbox-container',
              ) && (<HTMLElement>addedNode).querySelector('#affirm_overlay')
                ? [...nodeList, addedNode]
                : nodeList;
            },
            [],
          );
          if (containers.length) {
            // console.log('Found a container', ...containers);

            containers.forEach(async (el) => {
              watchForStyleChange(<HTMLElement>el);
            });
          }
        });
      }).observe(document.body, { childList: true });
    }
  }
};

export const triggerCheckout = (event: EbizioEventDetails) => {
  const checkoutButton = document.getElementById('checkout-payment-continue');

  if (
    checkoutButton &&
    event.selectedPaymentMethod?.id === 'bankdeposit' &&
    window.awaitingPaytomorrow
  ) {
    window.awaitingPaytomorrow = false;
    checkoutButton.click();
  }
};

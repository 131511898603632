export default () => {
  const phoneInput = document.getElementById('phoneInput');
  if (phoneInput) {
    const valueCheck = (event: Event) => {
      const target = event.currentTarget as HTMLInputElement;
      target.setAttribute('maxlength', '12');
      target.setAttribute('minlength', '10');
      target.value = target.value.replace(/[^\d\.\+]/g, '');

      const digits = (target.value.match(/\d/g) || []).length;

      const continueButton =
        document.getElementById('checkout-shipping-continue') ||
        document.getElementById('checkout-billing-continue');

      if (digits < 10 || digits > 11) {
        target.parentElement?.classList.add('form-field--error');
        (target.previousElementSibling as HTMLElement).innerText =
          'Please Enter a Proper Phone Number';
        (continueButton as HTMLInputElement).disabled = true;
      } else {
        target.parentElement?.classList.remove('form-field--error');
        (target.previousElementSibling as HTMLElement).innerText =
          'Phone Number';
        (continueButton as HTMLInputElement).disabled = false;
      }
    };
    phoneInput.addEventListener('change', valueCheck);
    phoneInput.addEventListener('keypress', valueCheck);
    phoneInput.addEventListener('keyup', valueCheck);
  }
};

import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';
import { triggerCheckout } from '../custom';

// NOTE: this will not fire all ALL payment methods loaded
// -- it's difficult to get right so if you need this hook and it's not working for your specific payment method please message Aaron
export const paymentMethodLoadedHandler = ({ detail }: EbizioEvent) => {
  logger('Payment Method Loaded');
  console.log(detail);

  triggerCheckout(detail);
};

export const paymentMethodLoadedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_METHOD_LOADED,
  handler: paymentMethodLoadedHandler,
};

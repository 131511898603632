import { EbizioEventDetails } from '../../types';

declare global {
  interface Window {
    extendLogo: string;
  }
}

export default (details: EbizioEventDetails) => {
  const extendItemsInCart = details.cart?.lineItems.customItems?.filter(
    (item) => item.name.includes('Extend'),
  );
  if (extendItemsInCart?.length) {
    extendItemsInCart.forEach((item) => {
      const findExtendFigure = (scope: HTMLElement = document.body) =>
        scope.querySelector(`[data-test-id="${item.id}"] .product-figure`);
      const appendImage = (target: Element) =>
        target.insertAdjacentHTML(
          'afterbegin',
          `<img alt="${item.name}" data-test="cart-item-image" src="${window.extendLogo}">`,
        );
      let extendFigure = findExtendFigure();
      if (extendFigure) {
        appendImage(extendFigure);
      } else {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.addedNodes.length) {
              console.log(mutation.addedNodes);
              mutation.addedNodes.forEach((node) => {
                if (node.nodeType === 1) {
                  extendFigure = findExtendFigure(node as HTMLElement);
                  if (extendFigure) {
                    appendImage(extendFigure);
                    observer.disconnect();
                  }
                }
              });
            }
          });
        });
        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      }
    });
  }
};

import { lang, logger } from '../../utils';

export const addShippingProtectionLink = () => {
  const productFeeToggle = document.querySelector('.productFeeToggle');
  if (productFeeToggle) {
    productFeeToggle.insertAdjacentHTML(
      'afterend',
      `
        <a href="${lang(
          'add_shipping_protection_link.link',
        )}" class="productFeeToggleDescription" target="_blank">${lang(
        'add_shipping_protection_link.text',
      )}</a>
    `,
    );
  }
};

import { EbizioEventDetails } from '../../types';

const exclusions = ['Tire Recycling Fee', 'Retail Delivery Fee'];
const combinedLabel = 'Sales Tax';

export const combineAndMergeFees = async (detail: EbizioEventDetails) => {
  const taxesList = document.querySelector('[data-test="cart-taxes"]');
  const taxBlock = (label: String, value: String | Number) => `
        <div aria-live="polite" class="cart-priceItem cart-priceItem--ebizio-modified optimizedCheckout-contentPrimary">
            <span class="cart-priceItem-label">
                <span data-test="cart-price-label">${label}</span>
            </span>
            <span class="cart-priceItem-value">
                <span data-test="cart-price-value">$${
                  Number.isFinite(value) ? (<Number>value).toFixed(2) : value
                }</span>
            </span>
        </div>
    `;
  if (taxesList) {
    const mergedTaxes = taxBlock(
      combinedLabel,
      detail.taxes
        .filter((tax) => !exclusions.includes(tax.name))
        .reduce((acc, cur) => acc + cur.amount, 0),
    );
    const separatedTaxes = detail.taxes
      .filter((tax) => exclusions.includes(tax.name))
      .reduce((acc, cur) => acc + taxBlock(cur.name, cur.amount), '');
    taxesList.innerHTML = separatedTaxes + mergedTaxes;
  }
};

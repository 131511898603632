import { EbizioEventDetails } from '../../types';
import { lang } from '../../utils';

type FeeData = {
  identifier: string;
  value: Number;
  name: String;
  toggled: Boolean;
  [key: string]: any;
};

const toggleSelector = '.productFeeToggle';

export const autoAddShippingProtection = async (
  detail: EbizioEventDetails,
  shippingProtectionFeeName: string,
) => {
  // @ts-ignore
  const storeHash = window?.storeHash;
  if (detail.access.productFees && storeHash) {
    const feeInCart = detail.cart?.lineItems.physicalItems.reduce(
      (feeItem: any, physicalItem: any) =>
        physicalItem.name.indexOf(shippingProtectionFeeName) > -1
          ? physicalItem
          : feeItem,
      null,
    );

    const applyFee = (toggles: { [key: string]: Boolean } = {}) =>
      fetch(
        `https://checkout.ebizio.com/app/product_fee/apply?store_hash=${storeHash}`,
        {
          method: 'POST',
          body: JSON.stringify({
            store: storeHash,
            cart_id: detail.cart?.id,
            toggles: toggles,
          }),
        },
      ).then(
        (response) =>
          new Promise((res, rej) => {
            if (response.ok) {
              try {
                response.json().then((data) => {
                  const shippingProtectionFeeData = data.data.fees.reduce(
                    (obj: {} | null, fee: FeeData) =>
                      fee.name.indexOf(shippingProtectionFeeName) > -1 ? fee : obj,
                    null,
                  );
                  shippingProtectionFeeData
                    ? res(shippingProtectionFeeData)
                    : rej(data);
                });
              } catch (e) {
                rej(e);
              }
            } else {
              rej(response);
            }
          }),
      );

    if (feeInCart) {
      // if the fee is already enabled, remove any session notes on prevention
      setStoragePreventFee(false);

      // trip future fee auto-adds if removal is triggered by manual click
      const toggleElement = document.querySelector(toggleSelector);
      if (toggleElement) {
        listenForToggleClick(toggleElement);
      }

      addPriceMessageToToggle(feeInCart.listPrice);
    } else {
      applyFee()
        .then((fee) => {
          // use response to fill in pricing data message on toggle button
          addPriceMessageToToggle((fee as FeeData).value);

          // check to make sure the fee hasn't previously been removed manually in this session
          if (!getStoragePreventFee()) {
            // apply the shipping protection fee
            const toggleElement = document.querySelector(toggleSelector);
            if (toggleElement) {
              (toggleElement as HTMLElement).click();
              listenForToggleClick(toggleElement);
            }
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  }
};

const listenForToggleClick = (toggleElement: HTMLElement | Node) => {
  (toggleElement as HTMLElement).addEventListener(
    'click',
    () => {
      setStoragePreventFee(true);
    },
    { once: true },
  );
};

const getStoragePreventFee = () =>
  window.sessionStorage
    ? Boolean(window.sessionStorage.getItem('ebizio-product-fee-prevent'))
    : false;

const setStoragePreventFee = (prevent: Boolean) => {
  if (window.sessionStorage) {
    if (prevent) {
      window.sessionStorage.setItem(
        'ebizio-product-fee-prevent',
        String(prevent),
      );
    } else {
      window.sessionStorage.removeItem('ebizio-product-fee-prevent');
    }
  }
};

const addPriceMessageToToggle = (price: Number = 0) => {
  const productFeeToggleBodyWrapper = document.querySelector(
    '.productFeeToggle__body-wrapper',
  );
  if (productFeeToggleBodyWrapper) {
    productFeeToggleBodyWrapper.insertAdjacentHTML(
      'beforeend',
      `
            <p class="productFeeToggle__footer">${lang(
              'product_fees.guaranteed_delivery',
              { price: price.toFixed(2) },
            )}</p>
        `,
    );
  }
};

import phoneNumberValidation from '../custom/phone-number-validation/phone-number-validation';
import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const useNewShippingAddressSelectedHandler = (event: EbizioEvent) => {
  logger('Use New Shipping Address Selected');
  console.log(event.detail);
  phoneNumberValidation();
};

export const useNewShippingAddressSelectedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.USE_NEW_SHIPPING_ADDRESS_SELECTED,
  handler: useNewShippingAddressSelectedHandler,
};

import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';
import { addShippingProtectionLink } from '../custom';
import { autoAddShippingProtection } from '../custom/auto-add-shipping-production';
import { combineAndMergeFees } from '../custom';
import phoneNumberValidation from '../custom/phone-number-validation/phone-number-validation';

export const shippingStepLoadingHandler = ({ detail }: EbizioEvent) => {
  logger('Shipping Step Loading');
  console.log(detail);

  autoAddShippingProtection(detail, 'Guaranteed Delivery');
  addShippingProtectionLink();
  combineAndMergeFees(detail);
  phoneNumberValidation();
};

export const shippingStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.SHIPPING_STEP_LOADING,
  handler: shippingStepLoadingHandler,
};

import { trackAffirmClose } from '../custom';
import extendProductImage from '../custom/extend-product-image/extend-product-image';
import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';

export const checkoutLoadedHandler = (event: EbizioEvent) => {
  logger('Checkout Loaded');
  console.log(event.detail);

  trackAffirmClose();
  extendProductImage(event.detail);
};

export const checkoutLoadedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.CHECKOUT_LOADED,
  handler: checkoutLoadedHandler,
};

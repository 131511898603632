import { addCommentForTermsConsent } from '../custom';
import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';
import { combineAndMergeFees } from '../custom';

export const paymentStepLoadingHandler = ({ detail }: EbizioEvent) => {
  logger('Payment Step Loading');
  console.log(detail);

  // addCommentForTermsConsent(detail);

  combineAndMergeFees(detail);
};

export const paymentStepLoadingConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.PAYMENT_STEP_LOADING,
  handler: paymentStepLoadingHandler,
};

import { EbizioEventDetails } from '../../types';

export default (details: EbizioEventDetails) => {
  const mountBalanceValueFromShipping =
    details.shippingAddress?.customFields.reduce(
      (acc: any, cur) => (cur.fieldId === 'field_28' ? cur.fieldValue : acc),
      '',
    );
  if (mountBalanceValueFromShipping) {
    const targetMountBalanceBillingInput = document.getElementById(
      `field_28Input-${mountBalanceValueFromShipping}`,
    );
    if (targetMountBalanceBillingInput) targetMountBalanceBillingInput.click();
  }
};

import { EbizioEvent, EbizioEventConfig, EBIZIO_EVENT_TYPE } from '../types';
import { logger } from '../utils';
import { combineAndMergeFees } from '../custom';

export const shippingMethodSelectedHandler = ({ detail }: EbizioEvent) => {
  logger('Shipping Method Selected');
  console.log(detail);

  combineAndMergeFees(detail);
};

export const shippingMethodSelectedConfig: EbizioEventConfig = {
  event: EBIZIO_EVENT_TYPE.SHIPPING_METHOD_SELECTED,
  handler: shippingMethodSelectedHandler,
};
